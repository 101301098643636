<template>
    <div>
        <Loading :isActive="loading"></Loading>
        <Edit :arquivo="currentItem" :update="update" />
        <CCard v-for="grupo in arquivos" :key="grupo.nome_exibicao">
            <CCardHeader>
                {{ grupo.nome_exibicao }}
                <CBadge color="success" class="pull-right">
                    {{ grupo.arquivos.length }} arquivos
                </CBadge>
            </CCardHeader>
            <table class="table table-condensed table-hover">
                <tr v-for="arquivo in grupo.arquivos">
                    <td class="col-10">
                        {{ arquivo.nome_exibicao }}
                        <CBadge color="danger" class="pull-right" v-if="arquivo.editado">
                            Arquivo customizado
                        </CBadge>
                        <br>
                        <small class="text-muted">Caminho físico: {{ arquivo.caminho }}</small>
                    </td>
                    <td class="text-right col-2">
                        <CButton color="danger" size="sm" @click="deleteArquivo(arquivo)" v-if="arquivo.editado" v-c-tooltip="'Desfazer customização'">
                            <fa-icon icon="trash-alt" />
                        </CButton>
                        <CButton color="secondary" size="sm" @click="openPreview(arquivo)" class="ml-1">
                            <fa-icon icon="eye" />
                        </CButton>
                        <CButton color="primary" size="sm" @click="openEdit(arquivo)" class="ml-1">
                            <fa-icon icon="pencil-alt" />
                        </CButton>
                        <LogAlteracaoDados
                           tabela="/var/www/resources/custom_views"
                           :chave="arquivo.caminho"
                        />
                    </td>
                </tr>
            </table>
        </CCard>
    </div>
</template>

<script>
  import { get, put, del, open } from '../../helpers/apiRequest';
  import Edit from "./Edit";
  import clone from 'just-clone';

  export default {
    name: "ViewsTemplates",
    components: {
      Edit
    },
    data() {
      return {
        'loading' : false,
        'arquivos': [],
        'currentItem': {},
      }
    },
    methods: {
      loadArquivos() {
        get(`/admin/views-templates`)
          .then((json) => {
            this.arquivos = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      openEdit(arquivo) {
        get(`/admin/views-templates/conteudo?caminho=${arquivo.caminho}`)
          .then((conteudo) => {
            this.currentItem = clone(arquivo);
            this.currentItem.conteudo = conteudo.conteudo;
            this.$modal.show('templateEdit');
            this.loading = false;
          })
          .catch((err) => {
            this.$swal({
              title: 'Falha ao carregar conteúdo',
              text: err.message,
              icon: 'error',
            });
          })
          .finally(() => this.loading = false);
      },
      openPreview(arquivo) {
        // monta url de preview
        // abre em nova aba
        console.log(arquivo);
        const url = `/admin/views-templates/preview?caminho=${arquivo.caminho}`;
        open({ url, newTab: true });
      },
      update() {
        this.loading = true;
        put(`/admin/views-templates`, this.currentItem)
          .then((json) => {
            this.$modal.hide('templateEdit');
            this.loadArquivos();
            this.$swal({
              title: 'Salvo com sucesso',
              text: json.message,
              showCancelButton: false,
              cancelButtonText: 'Fechar',
              showConfirmButton: true,
              icon: 'success',
            });
          })
          .catch((error) => {
            this.$swal({
              title: 'Falha ao Salvar',
              text: error.message,
              showCancelButton: true,
              cancelButtonText: 'Fechar',
              showConfirmButton: false,
              icon: 'error',
            });
          })
          .finally(() => this.loading = false);
      },
      deleteArquivo(arquivo) {
        this.$swal({
          title: 'Descartar alterações ?',
          text: 'Essa ação não poderá ser desfeita',
          showCancelButton: true,
          icon: 'warning',
        })
        .then((res) => {
          if (res.isConfirmed) {
            this.confirmDelete(arquivo);
          }
        });
      },
      confirmDelete(arquivo) {
        this.loading = true;
        del(`/admin/views-templates`, arquivo)
          .then((json) => {
            this.loadArquivos();
            this.$swal({
             title: 'Descartado com sucesso',
             text: json.message,
             showCancelButton: false,
             cancelButtonText: 'Fechar',
             showConfirmButton: true,
             icon: 'success',
           });
          })
          .catch((err) => {
            this.$swal({
              title: 'Falha ao descartar conteúdo',
              text: err.message,
              icon: 'error',
            });
          })
          .finally(() => this.loading = false);
      }
    },
    beforeMount() {
      this.loading = true;
      this.loadArquivos();
    }
  }
</script>